import * as Sentry from '@sentry/gatsby';
import axios from 'axios';
import { useFormik } from 'formik';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import * as Yup from 'yup';
import { useTranslateFormErrors } from '../../shared/hooks';
import {
    getCountries,
    LAMBDA_FORM_HANDLER,
    RECAPTCHA_SITE_KEY,
    registerActiveCampaign,
    registerCapterra,
    registerFacebookEvent,
    registerFormConversion,
    registerLinkedinConversion
} from '../../shared/utils';
import Alert from '../UI/Alert/Alert';
import Button from '../UI/Button/Button';
import Input from '../UI/Input/Input';
import RecaptchaProtected from '../UI/RecaptchaProtected/RecaptchaProtected';

import * as classes from './EnterpriseForm.module.scss';

let formValues = {};

const EnterpriseForm = () => {
    const [ showSuccess, setShowSuccess ] = useState(false);
    const [ showError, setShowError ] = useState(false);
    const [ name, setName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ sending, setSending ] = useState(false);

    const { t } = useI18next();

    const { language } = useI18next();

    const username = 'NA';

    const recaptchaRef = useRef();
    useTranslateFormErrors();

    const formik = useFormik({
        initialValues: {
            firstName: process.env.NODE_ENV === 'development' && false ? 'FulvioTest' : '',
            lastName: process.env.NODE_ENV === 'development' ? 'MolinengoTest' : '',
            email: process.env.NODE_ENV === 'development' ? 'email@test.it' : '',
            phone: process.env.NODE_ENV === 'development' ? '011123456' : '',
            company: process.env.NODE_ENV === 'development' ? 'Test S.r.l.' : '',
            position: process.env.NODE_ENV === 'development' ? 'CTO' : '',
            pages: process.env.NODE_ENV === 'development' ? '10000' : '',
            message: process.env.NODE_ENV === 'development' ? 'Il mio messaggio 12345' : '',
            country: ''
        },
        validationSchema: Yup.object({
            firstName: Yup.string()
                .min(2)
                .required(),
            lastName: Yup.string(),
            country: Yup.string().required(),
            email: Yup.string().email().required(),
            phone: Yup.string().required(),
            company: Yup.string().min(2).required(),
            position: Yup.string(),
            pages: Yup.number().min(300).max(10_000_000).required(),
            message: Yup.string().min(10).required()
        }),
        onSubmit: _values => {
            console.log('Submitted', _values);
            formValues = _values;
            setSending(true);
            setShowError(false);

            console.log('Executing recaptcha');
            recaptchaRef.current.execute();
        }
    });

    const handleResolveRecaptcha = captcha => {
        console.log('Recaptcha OK');

        let referrer = document.referrer || 'NA';
        let query = 'NA';

        try {
            const savedReferrer = window.localStorage.getItem('referrer');
            if (!!savedReferrer) {
                referrer = savedReferrer;
                window.localStorage.removeItem('referrer');
            }

            const savedQuery = window.localStorage.getItem('query');
            if (!!savedQuery) {
                query = savedQuery;
                window.localStorage.removeItem('query');
            }
        }
        catch (e) {
            console.error(e);
        }

        axios.post(`${LAMBDA_FORM_HANDLER}/contact`, {
            captcha,
            values: formValues,
            language,
            username,
            referrer,
            query,
            form: 'enterprise'
        })
            .then(() => {
                setName(formValues.firstName);
                setEmail(formValues.email);
                setShowSuccess(true);

                document.body.scrollTop = document.documentElement.scrollTop = 0;

                if (window.location.host === 'www.aidacloud.com') {
                    // Conversion events
                    registerFormConversion(50);
                    registerLinkedinConversion();

                    posthog.identify(formValues.email, {
                        email: formValues.email
                    });

                    posthog.capture('form_submit', {
                        form: 'enterprise'
                    });

                    registerCapterra();
                    registerActiveCampaign('contact-request', formValues.email);
                    registerFacebookEvent('Contact', 5);
                }
            })
            .catch(err => {
                window.grecaptcha.reset();
                setShowError(true);
                Sentry.captureException(err);
            })
            .finally(() => {
                setSending(false);
            });
    };

    return <>
        {!showSuccess && <div className={classes.ContactForm}>
            <div className={classes.Title}><Trans>Fill in the below information to allow us to give you the best
                                                  answer</Trans></div>
            <form onSubmit={formik.handleSubmit}>
                <Input
                    id="firstName"
                    label={<>{t('common:contacts.form.firstName.label')}<span className="required">*</span></>}
                    elementConfig={{
                        placeholder: t('common:contacts.form.firstName.placeholder')
                    }}
                    onChange={formik.handleChange}
                    value={formik.values.firstName}
                    inline={true}
                    errors={formik.errors.firstName}
                    invalid={!!formik.errors.firstName}
                    shouldValidate={true}
                    touched={true}
                />
                <Input
                    id="lastName"
                    label={t('common:contacts.form.lastName.label')}
                    elementConfig={{
                        placeholder: t('common:contacts.form.lastName.placeholder')
                    }}
                    onChange={formik.handleChange}
                    value={formik.values.lastName}
                    inline={true}
                    errors={formik.errors.lastName}
                    invalid={!!formik.errors.lastName}
                    shouldValidate={true}
                    touched={true}
                />
                <Input
                    id="email"
                    label={<>{t('common:contacts.form.email.label')}<span className="required">*</span></>}
                    elementConfig={{
                        placeholder: t('common:contacts.form.email.placeholder')
                    }}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    inline={true}
                    errors={formik.errors.email}
                    invalid={!!formik.errors.email}
                    shouldValidate={true}
                    touched={true}
                />
                <Input
                    id="phone"
                    label={<>{t('common:contacts.form.phone.label')}<span className="required">*</span></>}
                    elementConfig={{
                        placeholder: t('common:contacts.form.phone.placeholder')
                    }}
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                    inline={true}
                    errors={formik.errors.phone}
                    invalid={!!formik.errors.phone}
                    shouldValidate={true}
                    touched={true}
                />
                <Input
                    id="country"
                    label={<>{t('common:contacts.form.country.label')}<span className="required">*</span></>}
                    onChange={formik.handleChange}
                    value={formik.values.country}
                    inline={true}
                    elementType="select"
                    options={getCountries().map(e => {
                        return {
                            value: e.displayValue,
                            displayValue: e.displayValue
                        };
                    })}
                    addEmptyOption={true}
                    errors={formik.errors.country}
                    invalid={!!formik.errors.country}
                    shouldValidate={true}
                    touched={true}
                />
                <Input
                    id="company"
                    label={<>{t('common:contacts.form.company.label')}<span className="required">*</span></>}
                    elementConfig={{
                        placeholder: t('common:contacts.form.company.placeholder')
                    }}
                    onChange={formik.handleChange}
                    value={formik.values.company}
                    inline={true}
                    errors={formik.errors.company}
                    invalid={!!formik.errors.company}
                    shouldValidate={true}
                    touched={true}
                />
                <Input
                    id="position"
                    label={t('common:contacts.form.position.label')}
                    elementConfig={{
                        placeholder: t('common:contacts.form.position.placeholder')
                    }}
                    onChange={formik.handleChange}
                    value={formik.values.position}
                    inline={true}
                    errors={formik.errors.position}
                    invalid={!!formik.errors.position}
                    shouldValidate={true}
                    touched={true}
                />
                <Input
                    id="pages"
                    label={<>{t('common:contacts.form.pages')}<span className="required">*</span></>}
                    onChange={formik.handleChange}
                    value={formik.values.pages}
                    inline={true}
                    errors={formik.errors.pages}
                    invalid={!!formik.errors.pages}
                    shouldValidate={true}
                    touched={true}
                    elementConfig={{
                        type: 'number',
                        min: 300,
                        max: 10_000_000
                    }}
                />
                <Input
                    id="message"
                    label={<>{t('common:contacts.form.message.label')}<span className="required">*</span></>}
                    onChange={formik.handleChange}
                    value={formik.values.message}
                    inline={true}
                    elementType="textarea"
                    elementConfig={{
                        placeholder: t('common:contacts.form.message.placeholder'),
                        rows: 6,
                        style: {
                            style: { 'resize': 'none' }
                        }
                    }}
                    errors={formik.errors.message}
                    invalid={!!formik.errors.message}
                    shouldValidate={true}
                    touched={true}
                />

                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={RECAPTCHA_SITE_KEY}
                    onChange={handleResolveRecaptcha}
                    size="invisible"
                />

                <div className={classes.Submit}>
                    <Button
                        btnType="Primary"
                        icon={[ 'fad', 'paper-plane' ]}
                        isSubmit={true}
                        loading={sending}
                    >
                        {t('common:contacts.form.button')}
                    </Button>
                    <RecaptchaProtected />
                </div>
            </form>
            {showError &&
                <div style={{ marginTop: '20px' }}><Alert type="Danger" title={t('common:contacts.form.error.title')}>
                    <Trans i18nKey="common:contacts.form.error.message">
                        <p>We're sorry, but an error occurred while submitting your request.</p>
                        <p>You can try again later, or email info@aidacloud.com.</p>
                        <p>We will do our best to respond to your inquiry as soon as possible.</p>
                    </Trans>
                </Alert></div>}
        </div>}
        {showSuccess && <Alert type="Success" title={t('common:contacts.form.success.title', { name: name })}>
            <Trans i18nKey="common:contacts.form.success.message" email={email}>
                <p>Your request will be reviewed shortly by a person from our team.</p>
                <p>Check your email {{ email }} and look for an email from support@aidacloud.com; if you don't see it
                   within a
                   few minutes, check your "junk mail" or "spam" folder.</p>
                <p>We will do our best to respond to your inquiry as soon as possible.</p>
            </Trans>
        </Alert>}
    </>;
};

export default EnterpriseForm;
